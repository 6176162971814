import axios from "./http";

// 详情页数据列表查询
export function getList(type) {
    return axios({
        url: `/datalyg/view/sublist/${type}`,
        method: "post"
    })
}
// 详情页数据列表具体信息查询
export function getListInfo(type) {
    return axios({
        url: `/datalyg/view/specialClass/${type}`,
        method: "Get"
    })
}