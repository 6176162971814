<template>
  <div class="case">
    <div style="width: 100%; padding-top: 90px; padding-bottom: 45px">
      <div class="case-title">
        <img src="~@a/images/case-images/case-info/case-info.png" alt="" />
      </div>
    </div>
    <!-- 内容区 -->
    <div style="width: 100%; padding-top: 45px">
      <div class="case-containerTem">
        <!-- 左侧导航 -->
        <div>
        <div class="case-containerTem-left">
          <div
            class="case-nav"
            v-for="(item, index) in tabList"
            :key="item.id"
            :class="[
              { active: active == index ? 'active' : '' },
              { active: id == item.id ? 'active' : '' },
            ]"
          >
            <!-- <div class="point">
              <div class="point-dot"></div>
              <div class="point-line"></div>
            </div> -->
            <div class="point-nav">
              <div class="point-nav-box"></div>
              <div
                class="point-nav-title"
                @click="casePointClick(index, item.typeCode, item.id)"
              >
                <a href="javascript:void(0);" :title="item.name">{{
                  item.name
                }}</a>
              </div>
            </div>
          </div>
        </div>
        </div>
        <!-- 右侧内容 -->
        <div class="case-containerTem-right">
          <div style="width: 100%; padding-top: 30px; padding-bottom: 60px">
            <div class="case-wisdom">
              <div class="case-wisdom-title">
                {{ this.result.categoryName }}
              </div>
              <div class="case-wisdom-line"></div>
            </div>
            <div class="case-wisdom-content" v-html="this.result.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getListInfo, getList } from "@/api/caseinfo.js";
export default {
  components: {},
  data() {
    return {
      active: 0,
      result: {},
      tabList: [],
      title: "",
      id: "",
      content: "",
    };
  },
  created() {
    this.casePointClick();
    this.getListArr();
    this.id = this.$route.query.id;
  },
  methods: {
    getListArr() {
      getList(this.$route.query.type).then((res) => {
        this.tabList = res.result;
        this.tabList.unshift({
          id: -1,
          name: this.$route.query.categoryName,
        });
      });
    },
    casePointClick(index, typeCode, id) {
      if (id == -1) {
        return;
      }
      this.id = "";
      if (index == undefined) {
        typeCode = this.$route.query.code;
      }
      getListInfo(typeCode).then((res) => {
        // console.log("res-----详情", res);
        this.result = res.result;
      });
      this.active = index;
    },
  },
};
</script>
<style lang="less" scoped>
.case {
  background: #f6f6f6;
  // width: 1200px;
  // margin: 0 auto;
  div {
    .case-title {
      width: 1200px;
      height: 93px;
      margin: 0 auto;
      > img {
        width: 100%;
        height: 100%;
      }
    }

    .case-containerTem {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .case-containerTem-left {
        background: #f6f6f6;
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        margin-bottom: 40px;

        .case-nav {
          margin-top: 6px;
          display: flex;
          &:nth-child(1) {
            .point-nav {
              .point-nav-box {
                height: 38px;
                font-size: 18px;
                background: #e35513;
                font-family: MicrosoftYaHei-Bold;
                // .point-nav-title {
                //   > a {
                //     color: #fff;
                //   }
                // }
              }
              .point-nav-title{
                a{
                  color: #fff;
                }
              }
            }
          }
          &.active {
            .point-nav {
              .point-nav-title {
                color: #fff;
                > a {
                  color: #fff;
                  &:hover {
                    color: #fff;
                  }
                }
              }
            }
          }
          .point {
            display: flex;
            flex-direction: column;
            .point-dot {
              height: 6px;
            }
            .point-line {
              width: 1px;
              height: 39px;
            }
          }
          &.active {
            .point-nav {
              .point-nav-box {
                background-color: #ec9000;
              }
            }
          }
          .point-nav {
            width: 180px;
            height: 38px;
            position: relative;

            .point-nav-box {
              width: 180px;
              height: 38px;
              // border: 1px solid #e35513;
              background: #fff;
            }

            .point-nav-title {
              padding-left: 20px;
              padding-right: 20px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-bottom: 6px;
              position: absolute;
              width: 100%;
              text-align: center;
              top: 50%;
              transform: translateY(-50%);
              font-size: 16px;
              font-family: MicrosoftYaHei;
              line-height: 41px;
              > a {
                color: #E35513;
                &:hover {
                  // color: #000;
                }
              }
            }
          }
        }
      }
      .case-containerTem-right {
        background-color: #fff;
        // padding-bottom: 40px;
        margin-bottom: 40px;
        padding-left: 40px;
        padding-right: 40px;
        width: 980px;
        div {
          .case-wisdom {
            width: 900px;
            .case-wisdom-title {
              width: 900px;
              margin: 0 auto;
              height: 46px;
              font-size: 30px;
              font-family: MicrosoftYaHei-Bold;
              line-height: 46px;
              color: #333333;
              font-weight: 600;
            }
            .case-wisdom-line {
              width: 900px;
              margin: 0 auto;
              height: 8px;
              background-color: #ec9000;
            }
          }
          .case-wisdom-content {
            font-size: 18px;
            margin-top: 36px;
            line-height: 36px;
          }
        }
      }
    }
  }
}
</style>
